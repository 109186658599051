<template>
	<w-layout v-if="accountingFirm" fill-height column>
		<w-flex scroll-y shrink>
			<w-section sticky :title="$t('admin_group.manager.general_informations')">
				<template v-slot:header>
					<w-btn-save :disabled="isArchived" @click="updateAccountingFirm()" />
				</template>
				<AccountingFirmForm v-model="accountingFirm" :disabled="isArchived" />
			</w-section>
			<w-section v-if="!isArchived" sticky :title="$t('admin_group.manager.used_modules')">
				<ModuleForm v-model="accountingFirm.modules" />
			</w-section>
			<w-section v-if="!isArchived" sticky :title="$t('admin_group.manager.used_applications')">
				<w-layout column px-1>
					<ApplicationsList v-model="accountingFirm.applications" />
				</w-layout>
			</w-section>
			<w-layout align-center justify-end px-3 row wrap>
				<WButton v-if="!isArchived && !isCreation" flat @click="archiveAccountingFirm">{{ $t('actions.deactivate') }}</WButton>
				<WButton v-if="isArchived && !isCreation" flat @click="restoreAccountingFirm">{{ $t('actions.restore') }}</WButton>
				<WButton v-if="isArchived && !isCreation" flat @click="permanentlyDeleteAccountingFirm">{{ $t('actions.definitly_delete') }}</WButton>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import GroupModuleGuard from '@/mixins/ModulesGuards/Group/GroupModuleGuard'

export default {
	name: 'GroupManagerForm',
	components: {
		AccountingFirmForm: () => ({
			component: import('@/components/Group/Manager/AccountingFirmForm')
		}),
		ApplicationsList: () => ({
			component: import('@/components/Group/Manager/ApplicationsList')
		}),
		ModuleForm: () => ({
			component: import('@/components/Group/Manager/ModuleForm')
		})
	},
	mixins: [GroupModuleGuard],
	data: function () {
		return {
			accountingFirm: null,
			domain: window.location.hostname
		}
	},
	computed: {
		...mapState({
			accountingFirmSelected: state => state.group.selected
		}),
		isArchived: function () {
			return this.accountingFirm && !!this.accountingFirm.deleted_at
		},
		isCreation: function () {
			return this.accountingFirm && !this.accountingFirm.id
		}
	},
	watch: {
		accountingFirmSelected: {
			deep: true,
			handler: 'setAccountingFirm',
			immediate: true
		}
	},
	methods: {
		archiveAccountingFirm: function () {
			this.$dialog
				.warning({
					text: this.$t('admin_group.manager.archive_accounting_firm_text', { name: this.accountingFirm.name }),
					title: this.$t('admin_group.manager.archive_accounting_firm_title'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						const accountingFirm = this.accountingFirm
						this.service.archiveAccountingFirm(accountingFirm)
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('admin_group.accounting_firm_archived'))
					}
				})
		},
		permanentlyDeleteAccountingFirm: function () {
			this.$dialog
				.warning({
					text: this.$t('admin_group.manager.delete_accounting_firm_text', { name: this.accountingFirm.name }),
					title: this.$t('admin_group.manager.delete_accounting_firm_title'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						const accountingFirm = this.accountingFirm
						this.service.permanentlyDeleteAccountingFirm(accountingFirm)
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('admin_group.accounting_firm_deleted'))
					}
				})
		},
		restoreAccountingFirm: function () {
			return this.service.restoreAccountingFirm(this.accountingFirm).then(accountingFirm => {
				Object.assign(this.accountingFirm, accountingFirm)
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('admin_group.accounting_firm_restored'))
			})
		},
		setAccountingFirm: function (accountingFirm) {
			if (!accountingFirm) {
				return
			}

			this.accountingFirm = { ...accountingFirm }
			if (this.isCreation) {
				this.accountingFirm.applications = this.applications
			} else if (!this.accountingFirm.applications) {
				this.accountingFirm.applications = []
			}
		},
		updateAccountingFirm: function () {
			return this.service.updateAccountingFirm(this.accountingFirm).then(res => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('admin_group.accounting_firm_updated'))
				return res
			})
		}
	}
}
</script>
